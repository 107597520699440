import { Controller } from '@hotwired/stimulus';

import { Modal } from 'mdb-ui-kit';

export default class extends Controller {
  static targets = ['modal']

  connect() {
    this.element.addEventListener('hidden.bs.modal', () => this.hideModal());
    this.modal = new Modal(this.element);
    this.modal.show();
  }

  // hide modal
  // action: "turbo-modal#hideModal"
  hideModal() {
    this.modal.hide();
    this.modalTarget.remove();
  }

  // hide modal on successful form submission
  // action: "turbo:submit-end->turbo-modal#submitEnd"
  submitEnd(e) {
    if (e.detail.success) {
      this.hideModal();
    }
  }
}
