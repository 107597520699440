/* eslint-disable no-restricted-globals */
/* eslint-disable class-methods-use-this */
import { Controller } from '@hotwired/stimulus';
import moment from 'moment';
import { duration } from '../src/Helpers/CustomerHelper';

export default class extends Controller {
  static targets = ['projects', 'task', 'end', 'duration', 'km', 'startTime', 'endTime', 'durationTime'];

  static values = {
    tasks: String,
  }

  connect() {
    this.hideAll();
    this.projectChanged();
  }

  hideAll() {
    this.endTarget.hidden = true;
    this.durationTarget.hidden = true;
    this.kmTarget.hidden = true;
    this.taskTarget.hidden = true;
  }

  showInputsForTask() {
    const project = this.getProject();
    const task = project.tasks.find(((item) => item.id == this.taskTarget.value));
    if (task.budget_type === 'km') {
      this.endTarget.hidden = true;
      this.durationTarget.hidden = true;
      this.kmTarget.hidden = false;
    } else {
      this.endTarget.hidden = false;
      this.durationTarget.hidden = false;
      this.kmTarget.hidden = true;
    }
    if (task.round_up_to_10 === true) {
      this.durationTimeTarget.step = 10;
    } else {
      this.durationTimeTarget.step = 1;
    }
  }

  getProject() {
    const projectId = this.projectsTarget.value;
    const tasks = JSON.parse(this.tasksValue);
    const project = tasks.find(((item) => item.id == projectId));
    return project;
  }

  projectChanged() {
    const projectId = this.projectsTarget.value;
    const taskId = this.taskTarget.options[this.taskTarget.selectedIndex].value;

    if (!projectId) {
      this.taskTarget.hidden = true;
      return;
    }
    this.taskTarget.innerHTML = '';
    const project = this.getProject();
    if (!project) {
      this.taskTarget.hidden = true;
      return;
    }
    const tasks = project.tasks.sort((a, b) => a.name.localeCompare(b.name));
    tasks.forEach((item) => {
      const opt = document.createElement('option');
      opt.value = item.id;
      opt.innerHTML = item.name + (item.description ? ` (${item.description})` : '');
      this.taskTarget.appendChild(opt);
    });
    if (taskId) {
      this.taskTarget.value = taskId;
    } else {
      this.taskTarget.selectedIndex = 0;
    }

    this.taskTarget.hidden = false;
    this.showInputsForTask();
  }

  taskChanged() {
    this.showInputsForTask();
  }

  decimalAdjust(type, _value, _exp) {
    let value = _value;
    let exp = _exp;
    // If the exp is undefined or zero...
    if (typeof exp === 'undefined' || +exp === 0) {
      return Math[type](value);
    }
    value = +value;
    exp = +exp;
    // If the value is not a number or the exp is not an integer...
    if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
      return NaN;
    }
    // Shift
    value = value.toString().split('e');
    value = Math[type](+(`${value[0]}e${value[1] ? (+value[1] - exp) : -exp}`));
    // Shift back
    value = value.toString().split('e');
    return +(`${value[0]}e${value[1] ? (+value[1] + exp) : exp}`);
  }

  calculateDuration() {
    const project = this.getProject();
    const task = project.tasks.find(((item) => item.id == this.taskTarget.value));
    if (!task) {
      return;
    }
    const startTime = moment(this.startTimeTarget.value, 'HH:mm', true);
    let endTime = moment(this.endTimeTarget.value, 'HH:mm', true);
    if (!endTime.isValid()) { return; }
    let mDuration = duration(startTime, endTime);
    if (task.round_up_to_10 === true) {
      mDuration = this.decimalAdjust('ceil', mDuration, 1);
      if (!isNaN(mDuration)) { endTime = moment(startTime).add(mDuration, 'minutes'); }

      if (endTime.isValid() && !isNaN(mDuration)) {
        this.endTimeTarget.value = endTime.format('HH:mm');
      }
    }
    this.durationTimeTarget.value = mDuration;
  }

  changeEndTime() {
    const startTime = moment(this.startTimeTarget.value, 'HH:mm');
    const mDuration = this.durationTimeTarget.value;
    this.endTimeTarget.value = moment(startTime).add(mDuration, 'minutes').format('HH:mm');
  }
}
