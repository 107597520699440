import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="monthly-budget"
export default class extends Controller {
  static targets = [
    'week',
    'month',
  ]

  static values = {
    factor: Number,
  }

  // connect() {
  //   // console.log(this.weekTarget);
  //   // console.log(this.monthTarget);
  //   // console.log(this.factorValue);
  // }

  weekChanged() {
    const week = this.weekTarget.value;
    this.monthTarget.value = week * this.factorValue;
  }

  monthChanged() {
    const month = this.monthTarget.value;
    this.weekTarget.value = month / this.factorValue;
  }
}
