import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['source', 'filterable', 'category']

  toggleActive(event) {
    event.srcElement.classList.toggle('active');
    this.filter();
  }

  filter(event) {
    const lowerCaseFilterTerm = this.sourceTarget.value.toLowerCase();
    const categories = this.categoryTargets.map((item) => {
      if (item.classList.contains('active')) {
        return item.value;
      }
    }).join('');
    const categoriesLowerCase = categories.toLowerCase();

    this.filterableTargets.forEach((el) => {
      const filterableKey = el.getAttribute('data-filter-key');
      const filterableCategory = el.getAttribute('data-filter-category')?.toLowerCase();

      if (categories && lowerCaseFilterTerm) {
        el.classList.toggle('filter--notFound', !(filterableCategory.includes(categoriesLowerCase) && filterableKey.includes(lowerCaseFilterTerm)));
      } else if (categories && !lowerCaseFilterTerm) {
        el.classList.toggle('filter--notFound', !filterableCategory.includes(categoriesLowerCase));
      } else if (!categories && lowerCaseFilterTerm) {
        el.classList.toggle('filter--notFound', !filterableKey.includes(lowerCaseFilterTerm));
      } else {
        el.classList.remove('filter--notFound');
      }
      // el.classList.toggle('filter--notFound', !filterableKey.includes(lowerCaseFilterTerm));
    });
  }
}
