import * as mdb from 'mdb-ui-kit';

// Function to refresh input element style when necessary

const mdbInputUpdate = function () {
  document.querySelectorAll('.form-outline').forEach((formOutline) => {
    new mdb.Input(formOutline).init();
  });
  document.querySelectorAll('.form-outline').forEach((formOutline) => {
    new mdb.Input(formOutline).update();
  });
};

document.addEventListener('turbo:load', () => {
  mdbInputUpdate();
});

document.addEventListener('turbo:render', () => {
  mdbInputUpdate();
});

document.addEventListener('DOMContentLoaded', () => {
  const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-mdb-toggle="popover"]'));
  const popoverList = popoverTriggerList.map((popoverTriggerEl) => new mdb.Popover(popoverTriggerEl));

  const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-mdb-toggle="tooltip"]'));
  const tooltipList = tooltipTriggerList.map((tooltipTriggerEl) => new mdb.Tooltip(tooltipTriggerEl));
});
