import '@hotwired/turbo-rails';
import { Application } from '@hotwired/stimulus';

import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers';
import NestedForm from 'stimulus-rails-nested-form';

const application = Application.start();
const context = require.context('controllers', true, /_controller\.js$/);
application.load(definitionsFromContext(context));

// Configure Stimulus development experience
application.debug = false;
window.Stimulus = application;

application.load(definitionsFromContext(context));
application.register('nested-form', NestedForm);

export { application };
