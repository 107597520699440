import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['items', 'button']

  connect() {
    if (this.itemsTargets.length <= 1) {
      this.buttonTarget.hidden = true;
    }
    this.toggle(true);
  }

  showMore() {
    this.toggle(false);
    this.buttonTarget.hidden = true;
  }

  toggle(hidden) {
    this.itemsTargets.forEach((element, index) => {
      if (index !== 0) {
        element.hidden = hidden;
      }
    });
  }
}
