import moment from 'moment';

export function round(value, decimals) {
  return Number(`${Math.round(`${value}e${decimals}`)}e-${decimals}`);
}

export function hourLeftInProjectForMonth(project, now = moment().startOf('day')) {
  const {
    startDate, endDate, hoursServed, monthlyBudget,
  } = project;

  // const now = moment().startOf('day');
  const start = moment(startDate).startOf('day');
  const end = moment(endDate).endOf('day');
  const duration = moment.duration(end.diff(start));
  const months = round(duration.asMonths(), 0);
  const durationToNow = moment.duration(now.diff(start));
  const monthsToNow = round(Math.floor(durationToNow.asMonths()), 0);

  const monthlyHours = monthlyBudget * 4.3;
  const totalHours = monthlyHours * months;
  const monthsHoursServed = [];
  for (let i = 1; i <= months; ++i) {
    let hoursServedN = 0;
    if ((monthlyHours * i) < hoursServed) {
      hoursServedN = monthlyHours;
    } else {
      hoursServedN = Math.max(hoursServed - (monthlyHours * (i - 1)), 0);
    }
    monthsHoursServed.push(hoursServedN);
  }

  const ret = {
    months,
    percent: (hoursServed / totalHours) * 100.0,
    hoursServed,
    hoursServedThisMonth: hoursServed - (monthlyHours * monthsToNow),
    totalHours,
    monthlyHours,
    monthsHoursServed,
    monthsToNow,
  };
  return ret;
}

export function todayString() {
  const d = moment();
  return d.format('YYYY-MM-DD');
}

export function now() {
  return moment().toISOString(true);
}

export function minDate() {
  const d = moment();
  d.subtract(2, 'weeks');
  return d.format('YYYY-MM-DD');
}

export function maxDate() {
  const d = moment();
  d.add(1, 'year');
  return d.format('YYYY-MM-DD');
}

export function lastHour() {
  let now = moment().seconds(0).milliseconds(0);
  now = now.subtract(1, 'hours').subtract(15, 'minutes');
  const reminder = 15 - (now.minute() % 15);
  return now.add(reminder, 'minutes').toISOString(true);
}

export function formatDateToISO(date) {
  const d = moment(date);
  return d.format('YYYY-MM-DD');
}

export function formatDate(date) {
  const d = moment(date);
  return d.format('DD.MM.YYYY');
}

export function formatTime(time) {
  const d = moment(time);
  return d.format('HH:mm');
}

export function formatBenefitTime(benefit) {
  const st = moment(benefit.startTime);
  const et = moment(benefit.endTime);

  let tail = '';

  if (benefit.km) {
    tail = ` - ${benefit.km} km`;
  } else {
    const duration = moment.duration(et.diff(st)).asMinutes();
    tail = ` - ${et.format('HH:mm')} = ${duration} min.`;
  }

  return st.format('HH:mm') + tail;
}

export function formatExtraBenefitTime(benefit) {
  const st = moment(benefit.startTime);
  const et = moment(benefit.endTime);

  const duration = moment.duration(et.diff(st));
  const km = benefit.km ? ` - ${benefit.km} km` : '';

  return `${st.format('HH:mm')} - ${duration.asMinutes()} min.${km}`;
}

export function duration(startTime, endTime) {
  const st = moment(startTime);
  const et = moment(endTime);
  return moment.duration(et.diff(st)).asMinutes();
}

export function age(date) {
  const now = moment();
  const d = moment(date);
  const duration = moment.duration(now.diff(d));
  return duration.years();
}

export function dueIn(date) {
  moment.locale('de');
  return moment(date).fromNow();
}

export function warnDueTill(date) {
  if (moment(date).isSameOrBefore(moment().add(7, 'days'))) {
    return true;
  }
  return false;
}

export function timeFromNow(date) {
  if (date === moment().format('DD.MM.YYYY')) {
    return 'Heute';
  } if (date === moment().subtract(1, 'days').format('DD.MM.YYYY')) {
    return 'Gestern';
  }
  return date;
}

export function projectIsCurrent(project) {
  return moment().isSameOrBefore(project.endDate).endOf('day');
}

export function changeDate(curr, date) {
  const newDate = moment(date);
  const result = moment(curr).year(newDate.year()).month(newDate.month()).date(newDate.date());
  return result.toISOString(true);
}

export function changeTime(curr, hour, minute) {
  const result = moment(curr).hour(hour).minute(minute);
  return result.toISOString(true);
}

export function combineDateTime(date, time) {
  const newDate = moment(date);
  const newTime = moment(time);
  const result = moment(newDate).hour(newTime.hour()).minute(newTime.minutes());
  return result.toISOString(true);
}

export function birthdayIn(date) {
  moment.locale('de');
  const birthday = moment(date);

  // uncomment this line to simulate it is your birthday and comment the next one to test it.
  // var today = moment("2017-03-25");
  const today = moment().format('YYYY-MM-DD');

  // calculate age of the person
  const age = moment(today).diff(birthday, 'years');
  moment(age).format('YYYY-MM-DD');

  let nextBirthday = moment(birthday).add(age, 'years');
  moment(nextBirthday).format('YYYY-MM-DD');

  /* added one more year in case the birthday has already passed
    to calculate date till next one. */
  if (nextBirthday.isSame(today)) {
    return 'Heute';
  }
  nextBirthday = moment(birthday).add(age + 1, 'years');
  return `in ${nextBirthday.diff(today, 'days')} Tagen`;
}

export function showBirthdayInfo(date) {
  moment.locale('de');
  const birthday = moment(date);

  // uncomment this line to simulate it is your birthday and comment the next one to test it.
  // var today = moment("2017-03-25");
  const today = moment().format('YYYY-MM-DD');

  // calculate age of the person
  const age = moment(today).diff(birthday, 'years');
  moment(age).format('YYYY-MM-DD');

  let nextBirthday = moment(birthday).add(age, 'years');
  moment(nextBirthday).format('YYYY-MM-DD');

  /* added one more year in case the birthday has already passed
    to calculate date till next one. */
  if (nextBirthday.isSame(today)) {
    return true;
  }
  nextBirthday = moment(birthday).add(age + 1, 'years');
  if (nextBirthday.diff(today, 'days') <= 2) {
    return true;
  }
  return false;
}

export function birthdayDateThisYear(date) {
  moment.locale('de');
  const birthday = moment(date);

  // uncomment this line to simulate it is your birthday and comment the next one to test it.
  // var today = moment("2017-03-25");
  const today = moment().format('YYYY-MM-DD');

  // calculate age of the person
  const age = moment(today).diff(birthday, 'years');
  moment(age).format('YYYY-MM-DD');

  let nextBirthday = moment(birthday).add(age, 'years');
  moment(nextBirthday).format('YYYY-MM-DD');

  // return formatDateToISO(nextBirthday)
  /* added one more year in case the birthday has already passed
    to calculate date till next one. */
  if (nextBirthday.isSame(today)) {
    return formatDateToISO(nextBirthday);
  }
  nextBirthday = moment(birthday).add(age + 1, 'years');
  return formatDateToISO(nextBirthday);
}
