import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'mapPane',
  ]

  static values = {
    markers: String,
  }

  connect() {
    if (window.google) {
      this.initGoogle();
    }
  }

  initGoogle() {
    const markers = JSON.parse(this.markersValue);

    const myLatlng = new google.maps.LatLng(markers[0].position);
    const mapOptions = {
      zoom: 12,
      center: myLatlng,
    };
    const map = new google.maps.Map(document.getElementById('map'), mapOptions);

    const googleMarkers = markers.map((marker) => {
      const infowindow = new google.maps.InfoWindow({
        content: marker.title,
        ariaLabel: marker.title,
      });
      const googleMarker = new google.maps.Marker({
        ...marker,
        map,
      });

      googleMarker.addListener('click', () => {
        infowindow.open({
          anchor: googleMarker,
          map,
        });
      });
      return googleMarker;
    });

    this.fitBoundsToVisibleMarkers(googleMarkers, map);
  }

  fitBoundsToVisibleMarkers(markers, map) {
    const bounds = new google.maps.LatLngBounds();

    for (let i = 0; i < markers.length; i++) {
      if (markers[i].getVisible()) {
        bounds.extend(markers[i].getPosition());
      }
    }

    map.fitBounds(bounds);
  }
}
