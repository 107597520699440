/* eslint-disable radix */
/* eslint-disable no-restricted-globals */
import { Controller } from '@hotwired/stimulus';
import IMask from 'imask';

export default class extends Controller {
  connect() {
    this.mask = IMask(this.element, {
      overwrite: true,
      autofix: true,
      mask: 'HH:MM',
      blocks: {
        HH: {
          mask: IMask.MaskedRange,
          placeholderChar: 'HH',
          from: 0,
          to: 23,
          maxLength: 2,
          autofix: 'pad',
        },
        MM: {
          mask: IMask.MaskedRange,
          placeholderChar: 'MM',
          from: 0,
          to: 59,
          maxLength: 2,
        },
      },
    });
  }

  disconnect() {
    this.mask?.destroy();
  }
}
