import { Controller } from '@hotwired/stimulus';
import moment from 'moment';

export default class extends Controller {
  static targets = ['multiProject', 'cashProject', 'startDate', 'endDate', 'category', 'task', 'monthlyBudget']

  connect() {
    this.multiProjectTargets.forEach((item) => { item.hidden = true; });
    this.cashProjectTargets.forEach((item) => { item.hidden = true; });
    this.toggleTask();
  }

  toggleMulti(event) {
    this.multiProjectTargets.forEach((item) => { item.hidden = !item.hidden; });
    this.endDateTarget.hidden = !this.endDateTarget.hidden;
  }

  toggleCash(event) {
    this.cashProjectTargets.forEach((item) => { item.hidden = !item.hidden; });
  }

  toggleTask(event) {
    if (this.categoryTarget.value === 'Tagesstätte') {
      this.taskTarget.hidden = false;
      this.monthlyBudgetTarget.hidden = true;
    } else {
      this.taskTarget.hidden = true;
      this.monthlyBudgetTarget.hidden = false;
    }
  }

  calcEndDate() {
    let text = 'Ungenügend Daten';
    const start_date = moment(this.startDateTarget.firstChild.value, 'DD.MM.YYYY');
    const duration = document.getElementById('duration').value;
    const repetitions = document.getElementById('repetitions').value;
    if (duration > 0 && repetitions > 0 && start_date.isValid()) {
      text = `End Datum: ${start_date.add((duration * repetitions) - 1, 'months').format('DD.MM.YYYY')}`;
      document.getElementById('multi-project-end').innerText = text;
    } else if (!(duration && repetitions)) {
      document.getElementById('multi-project-end').innerText = null;
    } else {
      document.getElementById('multi-project-end').innerText = text;
    }
  }

  calcMonthlyHours() {
    console.log(this.endDateTarget);
    const start_date = moment(this.startDateTarget.firstChild.value, 'DD.MM.YYYY');
    const end_date = moment(this.endDateTarget.firstChild.value, 'DD.MM.YYYY');
    const months = end_date.diff(start_date, 'months') + 1;

    const euro = document.getElementById('budget_in_cash').value;

    if ((euro > 0) && !isNaN(months)) {
      const hourly_benefit = 28.99;
      const yearly_hours = euro / hourly_benefit;
      const monthly_hours = yearly_hours / months;
      const week_hours = monthly_hours / 4.3;
      document.getElementById('project_monthly_budget').value = week_hours;
    }
  }

  // const calcMonthlyHours = () => {
  //   const start_date = moment($('#project_start_date').val());
  //   const end_date = moment($('#project_end_date').val());

  //   const months = end_date.diff(start_date, 'months') + 1;
  //   console.log(months);

  //   const euro = $('#budget_in_cash').val();
  //   if ((euro > 0) && !isNaN(months)) {
  //     const hourly_benefit = 28.99;
  //     const yearly_hours = euro / hourly_benefit;
  //     const monthly_hours = yearly_hours / months;
  //     const week_hours = monthly_hours / 4.3;
  //     return $('#project_monthly_budget').val(week_hours);
  //   }
  // };
}
