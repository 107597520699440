/* eslint-disable no-restricted-globals */
/* eslint-disable class-methods-use-this */
import { Controller } from '@hotwired/stimulus';
import moment from 'moment';
import { duration } from '../src/Helpers/CustomerHelper';

export default class MyController extends Controller {
  static targets = [
    'start',
    'end',
    'duration',
  ];

  static values = {
    round: Boolean,
  }

  connect() {
    // console.log(this.roundValue);
    // console.log(this.startTarget);
    // console.log(this.endTarget);
    // console.log(this.durationTarget);
  }

  decimalAdjust(type, _value, _exp) {
    let value = _value;
    let exp = _exp;
    // If the exp is undefined or zero...
    if (typeof exp === 'undefined' || +exp === 0) {
      return Math[type](value);
    }
    value = +value;
    exp = +exp;
    // If the value is not a number or the exp is not an integer...
    if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
      return NaN;
    }
    // Shift
    value = value.toString().split('e');
    value = Math[type](+(`${value[0]}e${value[1] ? (+value[1] - exp) : -exp}`));
    // Shift back
    value = value.toString().split('e');
    return +(`${value[0]}e${value[1] ? (+value[1] + exp) : exp}`);
  }

  calculateDuration() {
    const startTime = moment(this.startTarget.value, 'HH:mm', true);
    let endTime = moment(this.endTarget.value, 'HH:mm', true);
    if (!endTime.isValid()) { return; }
    let mDuration = duration(startTime, endTime);
    if (this.roundValue === true) {
      mDuration = this.decimalAdjust('ceil', mDuration, 1);
      if (!isNaN(mDuration)) { endTime = moment(startTime).add(mDuration, 'minutes'); }

      if (endTime.isValid() && !isNaN(mDuration)) {
        this.endTarget.value = endTime.format('HH:mm');
      }
    }
    this.durationTarget.value = mDuration;
  }

  changeEndTime() {
    const startTime = moment(this.startTarget.value, 'HH:mm');
    const mDuration = this.durationTarget.value;
    this.endTarget.value = moment(startTime).add(mDuration, 'minutes').format('HH:mm');
  }
}
