// eslint-disable-file no-use-before-define
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// import Rails from '@rails/ujs';
import * as ActiveStorage from '@rails/activestorage';
// import 'channels';

import '@fortawesome/fontawesome-free/js/all.min.js';
import '@fortawesome/fontawesome-free/css/all';
import '@mdi/font/css/materialdesignicons.min.css';
import bootstrap from 'bootstrap';
import 'styles/application.scss';
import 'mdb-ui-kit/js/mdb.min.js';
import 'packs/mdb.js';

import 'controllers';

// require('trix');
// require('@rails/actiontext');

import 'chartkick/chart.js';

// import 'controllers';

// require('slim-select/dist/slimselect.css');
// require('slim-select/dist/slimselect.min.css');

window.initMap = () => {
  const event = new Event('MapLoaded', { bubbles: true, cancelable: true });
  window.dispatchEvent(event);
};

// Rails.start();
ActiveStorage.start();
